
import Vue from 'vue'
import VueRouter from 'vue-router'
import Firing from '../views/Firing.vue'
import store from '../store/index'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/firing' // 重定向到 '/home' 路由
  },
  {
    path: '/firing',
    name: 'Firing',
    component: Firing
  },
  {
    path: '/home',
    name: 'Home',
    component: function () {
      return import('../views/Home.vue')
    }
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})
// 全局前置守卫
router.beforeEach((to, from, next) => {
  // 获取 token，这里假设您的 token 存储在 localStorage 中
  // const apptoken = localStorage.getItem('apptoken');
  const apptoken = store.state.apptoken
  // 根据 token 的有无进行路由导航
  if (to.path === '/home' && !apptoken) {
    // 如果访问 /home 但没有 token，则跳转到 /firing
    next('/firing')
  } else if (to.path === '/firing' && apptoken) {
    // 如果访问 /firing 但有 token，则跳转到 /home
    next('/home')
  } else {
    // 其他情况正常进行导航
    next()
  }
})

export default router
