/*
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2024-02-05 13:55:32
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2024-02-19 21:27:34
 * @FilePath: \maxima\src\utils\request.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import axios from 'axios'
import { Toast, Loading } from 'vant'
const http = axios.create({
    baseURL: 'https://members.maximadao.com/news', // 设置统一的请求前缀
    timeout: 5000, // 设置统一的超时时长
})

// 请求拦截器：在请求之前，请求拦截器可以检测到，在发出去之前做一些事
http.interceptors.request.use((config) => {
        const token = localStorage.getItem('token')
            // // 进度条开始动
            // 显示加载状态
        if (token) {
            config.headers.token = token
        }
        return config
    })
    // 响应拦截器
http.interceptors.response.use((res) => {
    // 成功的回调函数
    return res.data
}, (error) => {
    // 失败的回调函数
    console.error('请求失败！！！')
        // return Promise.reject(new Error('加载失败，请重新刷新'))
})

export default http