export default {
  message: {
    injoin:'injoinUS.png',
    GoldPass: 'Kartu Hijau Emas',
    JoinNow: 'Ikut serta',
    MyEarningsM: 'Pendapatan Saya/MAX',
    MyEarningsU: 'Pendapatan Saya/USDT',
    Participated: 'Tidak Ikut Serta',
    SilverPass: 'kartu hijau perak',
    MyParticipation: 'Partisipasi Saya',
    PromotionU: 'Total Promosi\n/USDT',
    AmountUSDT: 'Ekuitas Emas Saya/USDT',
    HashpowerMultiplier: 'Daya Komputasi Tumpang Tindih LP/Kali',
    Earnings: 'Pendapatan',
    available: 'Tidak ada konten untuk saat ini',
    EarningsUSDT: 'Pendapatan Promosi/USDT',
    Cumulative: "Pendapatan Promosi Terakumulasi/USDT",
    Time: 'Waktu',
    Type: 'Tipe',
    Amount: 'Jumlah',
    Source: 'Sumber',
    Promotion: 'Promosi',
    GiftMAX: "Hadiah Token/MAX",
    TotalCumulativMAX: 'Hadiah Token Terakumulasi/MAX',
    Gift: 'Hadiah',
    Orders: 'Pesanan',
    Invitations: 'Undangan',
    ofInvitations: "Jumlah Undangan (individu)",
    Participate: "Ikut Serta",
    SelectType: "Pilih Kartu Hijau",
    Due: 'Terbayar',
    AmountPaid: 'Dibayar',
    AddOrder: 'Tambah Pesanan',
    ConfirmPayment: "Konfirmasi Pembayaran",
    ParticipationOptions: 'Opsi Partisipasi',
    OrderInformation: 'Informasi Pesanan',
    USDTTransfer: 'Transfer USDT',
    PaymentMethod: 'Metode Pembayaran',
    MaximaWalletBalance: 'Saldo Dompet Maxima',
    PayNow: 'Bayar Sekarang',
    FriendlyReminder: 'Pemberitahuan',
    Cancelpayment: 'Apakah Anda ingin membatalkan pembayaran ini?',
    Cancel: 'Batalkan ',
    ContinuePayment: 'Lanjutkan Pembayaran',
    InsufficientBalance: 'Saldo tidak mencukupi',
    toMaximaWallet: 'Silakan transfer USDT ke Dompet Maxima',
    ReturntoHomepage: 'Kembali ke Beranda',
    Gotit: 'Mengerti',
    PaymentSuccessful: 'Pembayaran Berhasil',
    Confirm: 'Konfirmasi',
    PaymentFailed: 'Pembayaran Gagal'
  }
}
