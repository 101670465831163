export default {
  message: {
    injoin:'injoinUS.png',
    GoldPass: 'Tarjeta Verde Oro',
    JoinNow: 'Participar',
    MyEarningsM: 'Mis Ganancias/MAX',
    MyEarningsU: 'Mis Ganancias/USDT',
    Participated: 'No Participado',
    SilverPass: 'Tarjeta verde plateada',
    MyParticipation: 'Mi Participación',
    PromotionU: 'Promoción Total/USDT',
    AmountUSDT: 'Mi Equidad en Oro/USDT',
    HashpowerMultiplier: 'Potencia de Cálculo de Superposición LP/Veces',
    Earnings: 'Ganancias',
    available: 'Sin contenido por ahora',
    EarningsUSDT: 'Ganancias de Promoción/USDT',
    Cumulative: "Ganancias de Promoción Acumuladas/USDT",
    Time: 'Tiempo',
    Type: 'Tipo',
    Amount: 'Cantidad',
    Source: 'Fuente',
    Promotion: 'Promoción',
    GiftMAX: "Regalo de Tokens/MÁX",
    TotalCumulativMAX: 'Regalo de Tokens Acumulado/MAX',
    Gift: 'Regalo',
    Orders: 'Órdenes',
    Invitations: 'Invitación',
    ofInvitations: "Número de Invitaciones (individuos)",
    Participate: "Participar",
    SelectType: "Seleccionar Tarjeta Verde",
    Due: 'Pendiente de Pago',
    AmountPaid: 'Pagado',
    AddOrder: 'Agregar Orden',
    ConfirmPayment: "Confirmar Pago",
    ParticipationOptions: 'Opciones de Participación',
    OrderInformation: 'Información del Pedido',
    USDTTransfer: 'Transferencia USDT',
    PaymentMethod: 'Método de Pago',
    MaximaWalletBalance: 'Saldo de la Billetera Maxima',
    PayNow: 'Pagar Ahora',
    FriendlyReminder: 'Recordatorio',
    Cancelpayment: '¿Abandonar este pago?',
    Cancel: 'Abandonar ',
    ContinuePayment: 'Continuar con el Pago',
    InsufficientBalance: 'Saldo insuficiente',
    toMaximaWallet: 'Por favor, transfiera USDT a la Billetera Maxima',
    ReturntoHomepage: 'Volver a la Página de Inicio',
    Gotit: 'Entendido',
    PaymentSuccessful: 'Pago Exitoso',
    Confirm: 'Confirmar',
    PaymentFailed: 'Pago Fallido'
  }
}
