export default {
  message: {
    injoin:'injoinUS.png',
    GoldPass: '金メダルグリーンカード',
    JoinNow: '参加する',
    MyEarningsM: '私の収益/MAXq',
    MyEarningsU: '私の収益/USDT',
    Participated: '参加していない',
    SilverPass: 'シルバーグリーンカード',
    MyParticipation: '私の参加',
    PromotionU: '総プロモーション/USDT',
    AmountUSDT: '私のエクイティゴールド/USDT',
    HashpowerMultiplier: 'LPオーバーレイコンピューティングパワー/倍',
    Earnings: '収益',
    available: '現在コンテンツはありません',
    EarningsUSDT: 'プロモーション収益/USDT',
    Cumulative: "累積プロモーション収益/USDT",
    Time: '時間',
    Type: 'タイプ',
    Amount: '金メダルグリーンカード',
    Source: 'ソース ',
    Promotion: 'プロモーション',
    GiftMAX: "トークンギフト/MAX",
    TotalCumulativMAX: '累積トークンギフト/MAX',
    Gift: 'ギフト',
    Orders: '注文',
    Invitations: '招待',
    ofInvitations: "招待人数（人）",
    Participate: "参加する",
    SelectType: "グリーンカードを選択",
    Due: '支払うべき',
    AmountPaid: '実際に支払われた',
    AddOrder: '注文を追加',
    ConfirmPayment: "支払いを確認",
    ParticipationOptions: '参加オプション',
    OrderInformation: '注文情報',
    USDTTransfer: 'USDT送金',
    PaymentMethod: '支払方法',
    MaximaWalletBalance: 'Maximaウォレット残高',
    PayNow: '今すぐ支払う',
    FriendlyReminder: 'お知らせ',
    Cancelpayment: 'この支払いを放棄しますか？',
    Cancel: '放棄 ',
    ContinuePayment: '支払いを続行',
    InsufficientBalance: '残高不足',
    toMaximaWallet: 'MaximaウォレットにUSDTを入金してください',
    ReturntoHomepage: 'ホームに戻る',
    Gotit: '了解しました',
    PaymentSuccessful: '支払い成功',
    Confirm: '確認',
    PaymentFailed: '支払い失敗'
  }
}
