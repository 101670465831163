/*
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2024-02-05 13:55:32
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2024-02-05 18:26:42
 * @FilePath: \maxima\src\locales\ko-KR.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export default {
  message: {
    injoin:'injoinUS.png',
    GoldPass: '골드 그린 카드',
    JoinNow: '참여하러 가기',
    MyEarningsM: '내 수익/MAX',
    MyEarningsU: '내 수익/USDT',
    Participated: '참여하지 않음',
    SilverPass: '실버 그린 카드',
    MyParticipation: '내 참여',
    PromotionU: '총 홍보량/USDT',
    AmountUSDT: '내 골드 자본/USDT',
    HashpowerMultiplier: 'LP 중첩 계산력/배',
    Earnings: '수익',
    available: '내용 없음',
    EarningsUSDT: '홍보 수익/USDT',
    Cumulative: "누적 홍보 수익/USDT",
    Time: '시간',
    Type: '유형',
    Amount: '금메달 그린 카드',
    Source: '출처',
    Promotion: '홍보',
    GiftMAX: "토큰 선물/MAX",
    TotalCumulativMAX: '누적 토큰 선물/MAX',
    Gift: '선물',
    Orders: '주문',
    Invitations: '내용 없음',
    ofInvitations: "초대 인원수 (개)",
    Participate: "참여",
    SelectType: "그린 카드 선택",
    Due: '지불 예정',
    AmountPaid: '실제 지불',
    AddOrder: '주문 추가',
    ConfirmPayment: "지불 확인",
    ParticipationOptions: '참여 옵션',
    OrderInformation: '주문 정보',
    USDTTransfer: 'USDT 송금',
    PaymentMethod: '결제 방법',
    MaximaWalletBalance: 'Maxima 지갑 잔액',
    PayNow: '즉시 결제',
    FriendlyReminder: '안내',
    Cancelpayment: '이번 결제를 포기하시겠습니까?',
    Cancel: '포기 ',
    ContinuePayment: '결제 계속하기',
    InsufficientBalance: '잔액 부족',
    toMaximaWallet: 'Maxima 지갑으로 USDT 입금해주세요',
    ReturntoHomepage: '홈으로 돌아가기',
    Gotit: '알겠습니다',
    PaymentSuccessful: '결제 성공',
    Confirm: '확인',
    PaymentFailed: '결제 실패'
  }
}
