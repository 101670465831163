export default {
  message: {
    langSelect:"Lựa chọn ngôn ngữ",
    injoin:'injoinUS.png',
    GoldPass: 'Thẻ\nXanh Vàng',
    JoinNow: 'Tham gia',
    MyEarningsM: 'Thu nhập \ncủa tôi/MAX',
    MyEarningsU: 'Thu nhập của\ntôi/USDT',
    Participated: 'Không liên quan',
    SilverPass: 'Thẻ\nXanhBạc',
    MyParticipation: 'Sự tham gia\ncủa tôi',
    PromotionU: 'Tổng khuyến\n mãi/USDT',
    AmountUSDT: 'Quyền sở hữu\ncủa tôi/USDT',
    HashpowerMultiplier: 'Sức mạnh tính toán\nchồng chéo LP/Lần',
    Earnings: 'Thu nhập',
    available: 'Hiện chưa có nội dung',
    EarningsUSDT: 'Thu nhập\nKhuyến mãi/USDT',
    Cumulative: "Tổng thu nhập từ\nKhuyến mãi/USDT",
    Time: 'Thời gian',
    Type: 'Loại',
    Amount: 'Số tiền',
    Source: 'Nguồn',
    Promotion: 'Khuyến mãi',
    GiftMAX: "Quà token/MAX",
    TotalCumulativMAX: 'Quà token đã\ntích luỹ/MAX',
    Gift: 'Quà tặng',
    Orders: 'Đơn hàng',
    Invitations: 'Mời',
    ofInvitations: "Số lượng người\nmời (cá nhân)",
    Participate: "Tham gia",
    SelectType: "Chọn Thẻ Xanh",
    Due: 'Phải thanh toán',
    AmountPaid: 'Đã thanh\ntoán',
    AddOrder: 'Thêm\nĐơn hàng',
    ConfirmPayment: "Xác nhận Thanh toán",
    ParticipationOptions: 'Tùy chọn Tham gia',
    OrderInformation: 'Thông tin Đơn hàng',
    USDTTransfer: 'Chuyển USDT',
    PaymentMethod: 'Phương thức thanh toán',
    MaximaWalletBalance: 'Số dư Ví Maxima',
    PayNow: 'Thanh toán Ngay',
    FriendlyReminder: 'Lời nhắc',
    Cancelpayment: 'Bỏ thanh toán này?',
    Cancel: 'Bỏ ',
    ContinuePayment: 'Tiếp tục Thanh toán',
    InsufficientBalance: 'Số dư không đủ',
    toMaximaWallet: 'Vui lòng chuyển\nUSDT vào Ví Maxima',
    ReturntoHomepage: 'Quay lại Trang chủ',
    Gotit: 'Đã hiểu',
    PaymentSuccessful: 'Thanh toán thành công',
    Confirm: 'Xác nhận',
    PaymentFailed: 'Thanh toán thất bại'
  }
}
