/*
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2024-02-05 13:55:32
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2024-02-05 18:26:47
 * @FilePath: \maxima\src\locales\th-TH.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export default {
  message: {
    injoin:'injoinUS.png',
    GoldPass: 'บัตรเขียวทอง',
    JoinNow: 'เข้าร่วม',
    MyEarningsM: 'รายได้ของฉัน/MAX',
    MyEarningsU: 'รายได้ของฉัน/USDT',
    Participated: 'ไม่ได้เข้าร่วม',
    SilverPass: 'การ์ดสีเขียวเงิน',
    MyParticipation: 'การเข้าร่วมของฉัน',
    PromotionU: 'โปรโมชั่นรวม/USDT',
    AmountUSDT: 'สิทธิ์ของฉันในทอง/USDT',
    HashpowerMultiplier: 'พลังการคำนวณการซ้อนทับ LP/ครั้ง',
    Earnings: 'รายได้',
    available: 'ไม่มีเนื้อหาในขณะนี้',
    EarningsUSDT: 'รายได้จากการโปรโมชั่น/USDT',
    Cumulative: "รายได้จากการโปรโมชั่นสะสม/USDT",
    Time: 'เวลา',
    Type: 'ประเภท',
    Amount: 'จำนวน',
    Source: 'แหล่งที่มา',
    Promotion: 'การโปรโมชั่น',
    GiftMAX: "ของขวัญโทเค็น/MAX",
    TotalCumulativMAX: 'การให้ของขวัญโทเค็นสะสม/MAX',
    Gift: 'ของขวัญ',
    Orders: 'คำสั่งซื้อ',
    Invitations: 'เชิญชวน',
    ofInvitations: "จำนวนคนที่เชิญ (คน)",
    Participate: "เข้าร่วม",
    SelectType: "เลือกบัตรเขียว",
    Due: 'ที่ต้องจ่าย',
    AmountPaid: 'ที่จ่ายจริง',
    AddOrder: 'เพิ่มคำสั่งซื้อ',
    ConfirmPayment: "ยืนยันการชำระเงิน",
    ParticipationOptions: 'ตัวเลือกการเข้าร่วม',
    OrderInformation: 'ข้อมูลคำสั่งซื้อ',
    USDTTransfer: 'การโอนเงิน USDT',
    PaymentMethod: 'วิธีการชำระเงิน',
    MaximaWalletBalance: 'ยอดเงินในกระเป๋า Maxima',
    PayNow: 'ชำระเดี๋ยวนี้',
    FriendlyReminder: 'คำเตือน',
    Cancelpayment: 'ยกเลิกการชำระเงินนี้หรือไม่?',
    Cancel: 'ยกเลิก ',
    ContinuePayment: 'ดำเนินการชำระเงินต่อ',
    InsufficientBalance: 'ยอดเงินไม่เพียงพอ',
    toMaximaWallet: 'กรุณาโอน USDT ไปยังกระเป๋า Maxima',
    ReturntoHomepage: 'กลับสู่หน้าหลัก',
    Gotit: 'เข้าใจแล้ว',
    PaymentSuccessful: 'การชำระเงินสำเร็จ',
    Confirm: 'ยืนยัน',
    PaymentFailed: 'การชำระเงินล้มเหลว'
  }
}
