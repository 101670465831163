<template>
  <div class="fullscreen-video">
    <img src="../assets/image/DAPfiring.jpg" alt="" />
  </div>
</template>
<script>
import { getCheckUser } from '../api/index'
export default {
  data() {
    return {
      appToken: ''
    }
  },
  mounted() {
    setTimeout(() => {
      this.requestToken()
    }, 2000)
  },
  methods: {
    requestToken() {
      const that = this
      return new Promise((resolve, reject) => {
        if (window.ReactNativeWebView) {
          window.ReactNativeWebView.postMessage(JSON.stringify({
            type: "MAXIMA_token",
            url: window.location.origin
          }))
          // ... 其他代码
          Object.defineProperty(window, 'MAXIMA_BACK', {
            set: function (value) {
              if (value.status == 'ok') {
                this.appToken = value.token
                that.$store.commit('getappToken', value.token)
                if (this.appToken) {
                  that.$nextTick(() => {
                    getCheckUser({ door_token: value.token }).then(res => {
                      localStorage.setItem('token', res.data._token)
                      if (res.data._token) {
                        that.$router.push('/home')
                      } else {
                        return
                      }
                    })
                  })
                } else {
                  return
                }
              } else {
                window.ReactNativeWebView.postMessage(JSON.stringify({ type: "MAXIMA_token" }))
                resolve(false)
              }
            }
          })
        } else {
          console.error('你并非在NativeWeb运行')
          // // ... 其他代码
        //   const appToken = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczovL21heC1hcGkubWF4aW1hZGFvLmNvbS9hcGkvdXNlci9sb2dpbiIsImlhdCI6MTcxMjgxNzU3MiwiZXhwIjoxNzE0MDI3MTcyLCJuYmYiOjE3MTI4MTc1NzIsImp0aSI6IlhZb2JLd1JhWUhpWEVUOEciLCJzdWIiOiI4MSIsInBydiI6IjIzYmQ1Yzg5NDlmNjAwYWRiMzllNzAxYzQwMDg3MmRiN2E1OTc2ZjcifQ.yUPixw2Mr1Qb13CsFLBHkPBdD4IiFHZlqxWYu9Pel6k"
        //   if (appToken) {
        //     getCheckUser({ door_token: appToken }).then(res => {
        //       localStorage.setItem('token', res.data._token)
        //       that.$store.commit('getappToken', appToken)
        //       if (res.data._token) {
        //         this.$nextTick(() => {
        //           this.$router.push('/home')
        //         })
        //       } else {
        //         return
        //       }
        //     })
        //   } else {
        //     return
        //   }
        }
      })
    }
  }
};
</script>

<style scoped>
.fullscreen-video {
  position: fixed;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
  z-index: 999;
  object-fit: cover;
  /* 保持视频比例并填充整个容器 */
  background-color: #282c2c;
}

.fullscreen-video img {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  /* 保持视频比例，同时填充整个容器 */
}
</style>
