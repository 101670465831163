export default {
  message: {
    langSelect:"語言選擇",
    injoin:'injoinUS.png',
    GoldPass: '金牌綠卡',
    JoinNow: '去參與',
    MyEarningsM: '我的收益/MAX',
    MyEarningsU: '我的收益/USDT',
    Participated: '未參與',
    SilverPass: '銀牌綠卡',
    MyParticipation: '我的參與',
    PromotionU: '推廣總量/USDT',
    AmountUSDT: '權益金/USDT',
    HashpowerMultiplier: 'LP疊加算力/倍',
    Earnings: '收益',
    available: '暫無內容',
    EarningsUSDT: '推廣收益/USDT',
    Cumulative: "累計推廣收益/USDT",
    Time: '時間',
    Type: '類型',
    Amount: '金額',
    Source: '來源',
    Promotion: '推廣',
    GiftMAX: "代幣贈送/MAX",
    TotalCumulativMAX: '累計代幣贈送/MAX',
    Gift: '贈送',
    Orders: '訂單',
    Invitations: '邀請',
    ofInvitations: "邀請人數(個)",
    Participate: "參與",
    SelectType: "選擇綠卡",
    Due: '應付',
    AmountPaid: '實付',
    AddOrder: '添加訂單',
    ConfirmPayment: "確認支付",
    ParticipationOptions: '參與選項',
    OrderInformation: '訂單資訊',
    USDTTransfer: 'USDT轉賬',
    PaymentMethod: '付款方式',
    MaximaWalletBalance: 'Maxima錢包餘額',
    PayNow: '立即支付',
    FriendlyReminder: '溫馨提示',
    Cancelpayment: '是否放棄本次支付?',
    Cancel: '放棄 ',
    ContinuePayment: '繼續支付',
    InsufficientBalance: '餘額不足',
    toMaximaWallet: '請前往Maxima錢包轉入USDT',
    ReturntoHomepage: '返回首頁',
    Gotit: '知道了',
    PaymentSuccessful: '支付成功',
    Confirm: '確認',
    PaymentFailed: '支付失敗'
  }
}
