/*
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2024-02-05 13:55:32
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2024-02-05 14:59:11
 * @FilePath: \maxima\src\locales\en-US.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export default {
  message: {
    langSelect:"Language selection",
    injoin:'injoinUS.png',
    GoldPass: 'GoldPass',
    JoinNow: 'JoinNow',
    MyEarningsM: "My Earnings\n/MAX",
    MyEarningsU: "My Earnings\n /USDT",
    Participated: "Not\nParticipated",
    SilverPass: 'SilverPass',
    MyParticipation: 'My Participation',
    PromotionU: 'Total Promotion\n/USDT',
    AmountUSDT: 'My Equity fee\n/USDT',
    HashpowerMultiplier: 'LP Overlay\nHashpower/Multiplier',
    Earnings: 'Earnings',
    available: 'No content available',
    EarningsUSDT: 'Promotion Earnings/USDT',
    Cumulative: "Total Cumulative\nPromotion Earnings/USDT",
    Time: 'Time',
    Type: 'Type',
    Amount: 'Amount',
    Source: 'Source',
    Promotion: 'Promotion',
    GiftMAX: "Token Gift/MAX",
    TotalCumulativMAX: 'Total Cumulative\nToken Gift/MAX',
    Gift: 'Gift',
    Orders: 'Orders',
    Invitations: 'Invitations',
    ofInvitations: "Number of\nInvitations (individuals)",
    Participate: "Participate",
    SelectType: "Select Your Pass Type",
    Due: 'Due',
    AmountPaid: 'Amount\nPaid',
    AddOrder: 'Add Order',
    ConfirmPayment: "Confirm Payment",
    ParticipationOptions: 'Participation Options',
    OrderInformation: 'Order Information',
    USDTTransfer: 'USDT Transfer',
    PaymentMethod: 'Payment Method',
    MaximaWalletBalance: 'Maxima Wallet Balance',
    PayNow: 'Pay Now',
    FriendlyReminder: 'Friendly Reminder',
    Cancelpayment: 'Cancel this payment?',
    Cancel: 'Cancel ',
    ContinuePayment: 'Continue Payment',
    InsufficientBalance: 'Insufficient Balance',
    toMaximaWallet: 'Please transfer USDT\nto Maxima Wallet',
    ReturntoHomepage: 'Return to Homepage',
    Gotit: 'Got it',
    PaymentSuccessful: 'Payment Successful',
    Confirm: 'Confirm',
    PaymentFailed: 'Payment Failed'
  }
}
