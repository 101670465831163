import http from '../utils/request'
// post请求
// 登录（拿到app端的token即可）
export function getCheckUser(data) {
  return http({
    url: '/index/checkUser',
    method: 'POST',
    data
  })
}
// 参与
export function getBuy(data) {
  return http({
    url: '/maxdao/buy',
    method: 'POST',
    data
  })
}



// get请求
//收益记录
export function getRewardRecord(params) {
  return http({
    url: '/maxdao/getRewardRecord',
    method: 'get',
    params
  })
}
// 我的邀请
export function getTeam(params) {
  return http({
    url: '/maxdao/getTeam',
    method: 'get',
    params
  })
}
//订单列表
export function getOrderList(params) {
  return http({
    url: '/maxdao/getOrderList',
    method: 'get',
    params
  })
}
// 个人信息
export function getProfile(params) {
  return http({
    url: '/maxdao/profile',
    method: 'get',
    params
  })
}
// 获取商品信息
export function getGoods(params) {
  return http({
    url: '/maxdao/getGoods',
    method: 'get',
    params
  })
}
