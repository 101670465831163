import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// 引入全局样式
import './assets/common/style.css'
// 引入适配文件
import 'amfe-flexible'
// 字体引入
import "./assets/fonts/fonts.css"
// 引入vue-i81
import i18n from './locales/index'
Vue.config.devtools = process.env.NODE_ENV !== 'production'
Vue.config.productionTip = false
new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app')