<!--
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2024-02-05 13:55:32
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2024-02-19 21:48:19
 * @FilePath: \maxima\src\App.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->

<template>
  <div id="app" :class="fontStyle.fontFamily">
    <router-view />
  </div>
</template>
<script>
import i18n from './locales';
// import Bowser from 'bowser';
export default {
  mounted(){
    this.detectOperatingSystem();
  },
  data() {
    return {
      fontStyle: {
        fontFamily: 'Andmyfonts'
      },
      locales:'',
    }
  },
  methods: {
    detectOperatingSystem() {
      const userAgent = navigator.userAgent;
      if (/Android/i.test(userAgent)) {
        // 当前是 Android 系统
        this.fontStyle.fontFamily = 'Andmyfonts';
        if(this.locales === 'en-us'){
          this.fontStyle.fontFamily = 'AndmyfontsUs';
        }else{
          this.fontStyle.fontFamily = 'Andmyfonts';
        }
        // 在这里可以设置 Android 系统的字体样式
      } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
        // 当前是 iOS 系统
        console.log('当前操作系统是 iOS');
        // 在这里可以设置 iOS 系统的字体样式
        this.fontStyle.fontFamily = 'iosmyfonts';
        if(this.locales === 'en-us'){
          this.fontStyle.fontFamily = 'iosmyfontsUs';
        }else{
          this.fontStyle.fontFamily = 'iosmyfonts';
        }
      } else {
        // 其他操作系统
        console.log('当前操作系统不是 Android 也不是 iOS');
      }
    }
  },
  watch: {
  '$i18n.locale'(newLocale, oldLocale) {
    // 处理i18n.locale变化的逻辑
    this.locales = newLocale
    this.detectOperatingSystem();
  }
}
}
</script>
<style lang="less">
// 安卓
.Andmyfonts{
  font-family: '思源黑体'
}
// 英文
.AndmyfontsUs{
  font-family: 'Roboto';
}
// iOS
.iosmyfonts{
  font-family: '苹方';
}
// 英文
.iosmyfontsUs{
  font-family: 'San-Francisco';
}
</style>
