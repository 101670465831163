import Vue from 'vue'
import VueI18n from 'vue-i18n'
import enLocale from './en-US'
import zhLocale from './zh-CN'
import hkLocale from './zh-HK'
import esLocale from './es-ES'
import thLocale from './th-TH'
import viLocale from './vi-VN'
import idLocale from './id-ID'
import jpLocale from './ja-JP'
import koLocale from './ko-KR'


Vue.use(VueI18n)
const locale = localStorage.getItem('locale')
export const messages = {
  'en-us': enLocale,
  'zh-cn': zhLocale,
  'zh-HK': hkLocale,
  'es-ES': esLocale,
  'th-TH': thLocale,
  'vi-VN': viLocale,
  'id-ID': idLocale,
  'ja-JP': jpLocale,
  'ko-KR': koLocale

}
const i18n = new VueI18n({
  locale: locale, // 默认语言
  fallbackLocale: 'zh-cn', // 无法找到对应语言时使用的回退语言
  messages
})

export default i18n
