/*
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2024-02-05 13:55:32
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2024-02-05 15:06:21
 * @FilePath: \maxima\src\locales\zh-CN.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export default {
  message: {
    langSelect:"语言选择",
    injoin:'injoin.png',
    GoldPass: '金牌绿卡',
    JoinNow: '去参与',
    MyEarningsM: '我的收益/MAX',
    MyEarningsU: '我的收益/USDT',
    Participated: '未参与',
    SilverPass: '银牌绿卡',
    MyParticipation: '我的参与',
    PromotionU: '推广总量/USDT',
    AmountUSDT: '权益金/USDT',
    HashpowerMultiplier: 'LP叠加算力/倍',
    Earnings: '收益',
    available: '暂无内容',
    EarningsUSDT: '推广收益/USDT',
    Cumulative: "累计推广收益/USDT",
    Time: '时间',
    Type: '类型',
    Amount: '金额',
    Source: '来源',
    Promotion: '推广',
    GiftMAX: "代币赠送/MAX",
    TotalCumulativMAX: '累计代币赠送/MAX',
    Gift: '赠送',
    Orders: '订单',
    Invitations: '邀请',
    ofInvitations: "邀请人数(个)",
    Participate: "参与",
    SelectType: "选择绿卡",
    Due: '应付',
    AmountPaid: '实付',
    AddOrder: '添加订单',
    ConfirmPayment: "确认支付",
    ParticipationOptions: '参与选项',
    OrderInformation: '订单信息',
    USDTTransfer: 'USDT转账',
    PaymentMethod: '付款方式',
    MaximaWalletBalance: 'Maxima钱包余额',
    PayNow: '立即支付',
    FriendlyReminder: '温馨提示',
    Cancelpayment: '是否放弃本次支付?',
    Cancel: '放弃 ',
    ContinuePayment: '继续支付',
    InsufficientBalance: '余额不足',
    toMaximaWallet: '请前往Maxima钱包转入USDT',
    ReturntoHomepage: '返回首页',
    Gotit: '知道了',
    PaymentSuccessful: '支付成功',
    Confirm: '确认',
    PaymentFailed: '支付失败'
  }
}
